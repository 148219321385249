<template lang="html">
  <div class="products modal" :class="{show : (currentProduct && show)}">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <section class="section--products-options" v-if="(currentProduct && show)">
          <h3>{{currentProduct | lang('name', $parent.lang)}}</h3>
          <div class="utils--spacer-default"></div>

          <div :key="optionset.id" v-for="optionset in currentProduct.optionsets" class="block block--optionsets">
            <h3>{{optionset | lang('name', $parent.lang)}}</h3>
            <div class="utils--spacer-semi"></div>
            <table class="table table-no-border">
              <tr :key="product.id" v-for="product in optionset.products" class="input form-group checkbox" >
                <td><label for="checkbox">{{product | lang('name', $parent.lang)}}</label></td>
                <td><input type="checkbox" id="product.name" v-model="product.selected" @change="getTotalPrice()"></td>
                <td class="text-right">
                  <span v-if="!product.selected">
                    {{getIncrement(product)}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td class="text-right">{{totalPrice}} CHF</td>
              </tr>
            </table>
            <div class="utils--spacer-semi"></div>
            <div class="text-right">
              <div class="btn-group">
                <div class="btn btn-lg" @click="removeProduct()">
                  {{$parent.statics.selectionReturn[$parent.lang]}}
                </div>
                <div class="btn btn--black btn-lg color--white" @click="hide()">
                  {{$parent.statics.selectionAdd[$parent.lang]}}
                </div>
              </div>
            </div>
            <div class="utils--spacer-semi"></div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'product',
  data: function(){
    return {
      id: 0,
      model: '',
      show: 0,
      currentProduct: undefined,
      basePrice: 0,
      totalPrice: 0,
      realPrice: 0
    }
  },
  created: function() {

  },
  mounted: function(){
    this.$root.$on('optionsShow', (id, model) => {
      this.optionsShow(id, model)
    })
  },
  methods: {
    optionsShow: function(id, model){
      this.id = id
      this.model = model
      this.currentProduct = this.$parent.selectedProducts.find(current => current.id == this.id && current.model == this.model)
      if(this.currentProduct != undefined){
        this.initOptions()
        this.show = 1
      }
    },
    initOptions: function(){
      this.basePrice = this.currentProduct.price
      for(var i = 0; i < this.currentProduct.optionsets.length; i++){
        for(var y = 0; y < this.currentProduct.optionsets[i].products.length; y ++){
          this.currentProduct.optionsets[i].products[y].selected = (this.currentProduct.optionsets[i].products[y].option_default == true)
        }
      }
      this.getTotalPrice()
    },
    getTotalPrice: function() {
      this.totalPrice = this.currentProduct.price
      for(var i = 0; i < this.currentProduct.optionsets.length; i++){
        for(var y = 0; y < this.currentProduct.optionsets[i].products.length; y ++){
          var option = this.currentProduct.optionsets[i].products[y]
          if(option.selected == true && option.option_default != true){
            this.totalPrice += option.price
          }else if(option.selected == false && option.option_default == true){
            this.totalPrice -= option.price
          }
        }
      }
      this.realPrice = this.totalPrice
      if(this.totalPrice < this.basePrice) this.totalPrice = this.basePrice
    },
    removeProduct: function(){
      var keySelected = this.$parent.selectedProducts.findIndex(selected => selected.id === this.currentProduct.id && selected.model == this.currentProduct.model)
      if(keySelected !== -1){
        this.currentProduct = undefined
        this.$parent.selectedProducts.splice(keySelected, 1)
      }
    },
    getIncrement: function(option){
      var inc = 0;
      inc += option.price - (this.totalPrice - this.realPrice)
      return (inc > 0)? '+'+inc+' CHF' : ' '
    },
    hide: function(){
      this.show = false
    }
  }
}
</script>

<template lang="html">
  <header v-if="$parent.visibleHeader" :class="{'no-background': isHome()}">
    <div>
      <a class="pointer" v-if="isHome()" ><!-- @click="lngSwitch()" -->
        &nbsp;<!-- <b>{{$parent.statics.headerLngSwitch[$parent.lang]}}</b>-->
      </a>
      <a class="pointer" v-else @click="goBack">
        <b><i class="material-icons">chevron_left</i>{{$parent.statics.selectionReturn[$parent.lang]}}</b>
      </a>
    </div>
    <div>
      <router-link to="/">
        <img src="@/assets/img/logo--mami.svg" :width="isHome() ? 100 : 75">
      </router-link>
    </div>
    <div>
      <!--<router-link to="/selection" class="btn--select" :class="{active: $parent.selectedProducts.length > 0}">
        <div class="block block--blue-dark"></div> &nbsp;&nbsp;<span class="text--light">{{$parent.statics.headerSelection[$parent.lang]}}</span> &nbsp;&nbsp; <small>({{$parent.selectedProducts.length}})</small>
      </router-link>
    -->
    </div>
  </header>
</template>
<script>
export default {
  methods: {
    lngSwitch: function(){
      this.$parent.lang = (this.$parent.lang == 'fr_CH')? 'en_GB' : 'fr_CH'
    },
    goBack: function() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/')
    },
    isHome: function() {
      return (this.$route.name == 'home')
    }
  },
}
</script>

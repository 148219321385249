<template lang="html">
  <footer v-if="!$parent.visibleHeader">
    <a @click="goBack">
      <b>{{$parent.statics.selectionReturn[$parent.lang]}}</b>
    </a>
    <router-link to="/">
      <img src="@/assets/img/eligo.png" width="100">
    </router-link>
    <router-link to="/">
      <span class="text--light">{{$parent.statics.menuTitle[$parent.lang]}}</span>
    </router-link>
  </footer>
</template>
<script>
export default {
  methods: {
    goBack: function() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/')
    },
  }
}
</script>

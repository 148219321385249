import axios from 'axios'

const
client = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    //'X-CSRF-TOKEN'    : window.csrfToken,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}),
parseResponse = function(response)
{
  return {
    data: response.data.data // expecting object with ID
  }
}

export { client, parseResponse }

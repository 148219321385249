import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/restaurants/View.vue')
    },
    {
      path: '/categories/:id',
      name: 'categories',
      component: () => import(/* webpackChunkName: "category" */ './views/categories/View.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/pages/About.vue')
    },
    {
      path: '/selection',
      name: 'selection',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "selection" */ './views/pages/Selection.vue')
    },
    {
      path: '/drinks',
      name: 'drinks',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "drinks" */ './views/drinks/View.vue')
    },
    {
      path: '/wine-types',
      name: 'wine-types',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "wine-types" */ './views/wines/Index.vue')
    },
    {
      path: '/wine-types/:id',
      name: 'wine-type',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "wine-type" */ './views/wines/View.vue')
    },
    {
      path: '/specials/:id',
      name: 'specials',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "specials" */ './views/specials/View.vue')
    },
    {
      path: '/menus/:id',
      name: 'menus',
      component: () => import(/* webpackChunkName: "menus" */ './views/menus/Index.vue')
    },
    {
      path: '/menus/:catid/:id',
      name: 'menu',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "menu" */ './views/menus/View.vue')
    }
  ]
})
